<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="isAdd ? '新增经销商组信息' : '编辑经销商组信息'" @closed="form = null">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="98px" label-suffix=":" v-if="form">
      <el-form-item label="经销商组" prop="name">
        <el-input v-model="form.name" maxlength="40" />
      </el-form-item>
      <el-form-item label="描述">
        <el-input type="textarea" v-model="form.remarks" maxlength="150" :rows="3"/>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="dialog = false">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { add, edit } from "@/api/distributorGroup";

export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      searching: false,
      dialog: false,
      visible: false,
      dealerData: [],
      form: null,
      rules: {
        name: [{ required: true, message: "请输入经销商组名称", trigger: "blur" }],
      }
    };
  },

  methods: {
    doSubmit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      add(this.form)
        .then(res => {
          this.$notify({
            title: "添加经销商组成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.form)
        .then(res => {
          this.$notify({
            title: "编辑经销商组成功",
            type: "success",
            duration: 2500
          });
          this.loading = false;
          this.dialog = false;
          this.$parent.init();
        })
        .catch(err => {
          this.loading = false;
        });
    },
    resetForm(form) {
      this.form = form || {
        name: "",
        remarks: ""
      };
      this.dialog = true;
    },
  }
};
</script>
