<template>
  <div class="app-container">
    <!--工具栏-->
    <div class="head-container">
      <el-input v-model="query.name" clearable placeholder="输入经销商组搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-input v-model="query.disCode" clearable placeholder="输入经销商编码搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>

      <div style="display: inline-block;margin: 0px 2px;">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <eMemberForm ref="memberForm" :is-view="false" />
    <!--表格渲染-->
    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="name" label="经销商组" min-width="150" />
      <el-table-column prop="remarks" label="描述" min-width="200" />
      <el-table-column prop="disCount" label="经销商数量" width="100" align="center" />
      <el-table-column prop="createBy" label="创建人" width="100" />
      <el-table-column label="创建时间" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd hh:mm:ss'); }" width="150" />
      <el-table-column width="150px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="editDis(scope.row.id,scope.row.name)">成员</el-button>
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { add, edit, del } from "@/api/distributorGroup";
import eForm from "./form";
import eMemberForm from "./members";

export default {
  components: { eForm, eMemberForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      query: {
        name: null,
      },
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/distributorGroup";
      const sort = "createAt,desc";
      this.params = Object.assign(
        { page: this.page, size: this.size, sort: sort },
        this.query
      );
      return true;
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    add() {
      this.isAdd = true;
      this.$refs.form && this.$refs.form.resetForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(data)));
    },
    editDis(groupId, name) {
      this.$refs.form && this.$refs.memberForm.refresh(groupId, name);
    },
  },
};
</script>